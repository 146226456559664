import { remove, runInAction } from "mobx";
import { getUuid } from "../../../modules/id-generator";
import root from "../../";

export const addSuccess = (info) => {
    const id = getUuid();

    runInAction(() => {
        root.errorInfo.errs[id] = {
            id,
            ti: info,
            typ: "success",
            hide: setTimeout(() => {
                hideError(id);
            }, 3000),
        };
    });

    return id;
};

export const addError = (info, act) => {
    const id = getUuid();

    runInAction(() => {
        root.errorInfo.errs[id] = {
            id,
            ti: info,
            act,
        };
    });

    return id;
};

export const hideError = (id) => {
    runInAction(() => {
        remove(root.errorInfo.errs, id);
    });
};

export const hideAllErrors = () => {
    runInAction(() => {
        root.errorInfo.errs = {};
    });
};
